import student from "../images/student.png"
import teacher from "../images/teacher.png"

const MessageDetails = () => {
    const msg = [
        {
            img: teacher,
            name: "Teacher",
            time: "2 mins",
            msg: [
                "Read up more about arithmetic operators John!",
                "Line 10:",
                "You are computing the remainder incorrectly. Use a modulo operator instead of the division operator.",
                "Fix:",
                "n % i == 0",
            ]
        },
        {
            img: student,
            name: "John",
            time: "now",
            msg: ["Thanks Teacher!"]
        },
    ]

    return msg
}

export default MessageDetails