import "../../../../styles/protected/content/StudentDemo.css"
import "../../../../styles/protected/BaseStyles.css"
import { FaLaptopCode, FaLightbulb } from "react-icons/fa"
import { BiBot, BiChevronRight } from "react-icons/bi"
import { MdVerified } from "react-icons/md"
import { useEffect, useState } from "react"
import 'aos/dist/aos.css';
// @ts-ignore
import AOS from 'aos';
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';


const StudentDemo3 = () => {

    const lineNums = []
    for (let i = 1; i <= 15; i++) {
        lineNums.push(i)
    }

    useEffect(() => {

        const typingEffectDelay = setTimeout(() => {
            let editor = document.querySelector(".student-solution-box")

            let typewriter = new Typewriter(editor, {
                delay: 100
            })

            typewriter
                .pauseFor(500)
                .deleteAll()
                .changeDeleteSpeed(1000)
                .pauseFor(500)
                .typeString('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style="color: #FF6E4F"></span>if n<span style="color: #3CD6EB"> % </span>i <span style="color: #3CD6EB">==</span> 0:')
                // .pasteString('<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style="color: #FF6E4F">return</span> “No”')
                // .pasteString('<br />')
                // .pasteString('<br/>&nbsp;&nbsp;&nbsp;  <span style="color: #FF6E4F">return</span> “Yes”')
                .start()
        }, 2000)

        return () => {
            clearTimeout(typingEffectDelay)
        }
    }, [])

    return (
        <div className='student-demo-container'>

            <div className="icons-box">
                <div className="line-marker">
                    <div className="icon-btn"><FaLaptopCode className="icon medium-icon" style={{ color: "#FF6363" }} /></div>
                    <p className="icon-title very-small-text light-bold">Incorrect Program</p>
                </div>

                <div className="line-marker">
                    <div className="icon-btn" style={{ boxShadow: "3px 3px 80px rgba(99, 245, 255, 1), -3px -3px 80px rgba(99, 245, 255, 1)" }}><BiBot className="icon medium-icon" style={{ color: "#33DAFF" }} /></div>
                    <p className="icon-title very-small-text light-bold">Automated Hint</p>

                </div>
                <div className="line-marker">
                    <div className="icon-btn" ><MdVerified className="icon medium-icon" style={{ color: "#33FF6C" }} /></div>
                    <p className="icon-title very-small-text light-bold" >Successful Submission</p>
                </div>
            </div>

            <div className="code-box">
                <div className="student-code-headers">
                    <p className="small-text" style={{ color: "#a0abc4" }}>Loops</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <div className="student-header small-text light-bold" style={{ color: "white" }}>Prime Number</div>
                </div>
                <div className="student-code">
                    <div className="code-line-numbers">
                        {lineNums.map(num => {
                            return (
                                <div className="line-numbers line-numbers-space std-demo-3-linespace small-text light-bold" style={{ color: "#6E7681" }}>{num}</div>
                            )
                        })}
                        {/* <div className="hint-line-icon"><FaLightbulb /></div> */}


                    </div>
                    <div className="code">
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># Write a python program that takes N as input:</p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns Yes if number is prime</p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns No otherwise</p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <div className="highlight-hint-line"></div>
                        {/* <div className="line-numbers small-text light-bold student-solution-box"></div> */}

                        <p className="line-numbers small-text light-bold"><span style={{ color: "#FF6E4F" }}>def</span> <span style={{ color: "#C7A8F2" }}>is_prime</span>(n):</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp; <span style={{ color: "#FF6E4F" }}>if</span> n <span style={{ color: "#3CD6EB" }}>== 1</span>:</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> "No"</p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>for</span> i in <span style={{ color: "#FF6E4F" }}>range</span> (<span style={{ color: "#3CD6EB" }}>2</span>, n):</p>

                        <div className="line-numbers small-text light-bold student-solution-box"><p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}></span>if n<span style={{ color: "#3CD6EB" }}> / </span>i <span style={{ color: "#3CD6EB" }}>==</span> 0:</p></div>

                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “No”</p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “Yes”</p>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default StudentDemo3