import { useEffect, useState } from "react"
import "../../../../styles/protected/content/ProvenImpact.css"
import CountUp, { useCountUp } from 'react-countup';
import 'aos/dist/aos.css';
// @ts-ignore
import AOS from 'aos';
import linesImg from "../../../../images/lines.png"

const ProvenImpact = () => {
    const [teacherNumber, setTeacherNumber] = useState(0)
    const [studentNumber, setStudentNumber] = useState(0)
    const [conceptNumber, setConceptNumber] = useState(0)

    return (
        <div className='impact-container' id="proven-impact-page">
            <div className="impact-box" >

                <p className="large-text extra-bold" id="proven-impact-page">Proven Impact</p>
                <p className="medium-small-text light-bold" style={{ color: "#a1acc5" }}>Study conducted with 450+ programming students and 35 teachers at a leading Computer Science University</p>

                <div className="stats-container">

                    <div className="stats-box">
                        <p style={{ color: "#58FF87", marginTop: "60px" }} className="large-text light-bold">Easier</p>
                        <p className="medium-small-text light-bold" style={{ marginTop: "20px", lineHeight: "35px", color: "white" }}>Teacher<br />Effort Reduction</p>
                        <div className="number-box"></div>
                        <CountUp
                            start={0}
                            end={22}
                            suffix=" %"
                            delay={1.5}
                            enableScrollSpy
                            // onEnd={({ pauseResume, reset, start, update }) => start()}
                            duration={1.3}
                            className="counter extra-extra-large-text max-bold"
                        // style={{ fontSize: "80px" }}
                        ></CountUp>

                    </div>

                    <div className="stats-box" style={{ border: "7px solid #E8FF58" }}>
                        <p style={{ color: "#E8FF58", marginTop: "60px" }} className="large-text light-bold">Faster</p>
                        <p className="medium-small-text light-bold" style={{ marginTop: "20px", lineHeight: "35px", color: "white" }}>Student<br />Learning Efficiency</p>
                        <div className="number-box" style={{ backgroundColor: "#0b101a" }}></div>
                        <CountUp
                            start={0}
                            end={25}
                            suffix=" %"
                            delay={1.5}
                            enableScrollSpy
                            // onEnd={({ pauseResume, reset, start, update }) => start()}
                            duration={1.3}
                            className="counter extra-extra-large-text max-bold"
                        // style={{ fontSize: "80px" }}
                        ></CountUp>

                    </div>

                    <div className="stats-box" style={{ border: "7px solid #33DAFF" }}>
                        <p style={{ color: "#33DAFF", marginTop: "60px" }} className="large-text light-bold">Better</p>
                        <p className="medium-small-text light-bold" style={{ marginTop: "20px", lineHeight: "35px", color: "white" }}>Student<br />Concept Retention</p>
                        <div className="number-box" style={{ backgroundColor: "#080D16" }}></div>
                        <CountUp
                            start={0}
                            end={15}
                            suffix=" %"
                            delay={1.5}
                            enableScrollSpy
                            // onEnd={({ pauseResume, reset, start, update }) => start()}
                            duration={1.3}
                            className="counter extra-extra-large-text max-bold"
                        // style={{ fontSize: "80px" }}

                        ></CountUp>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default ProvenImpact


// The numbers %ages will be
//      25% reduced teacher effort
//      15% concept retention
//      22% faster students