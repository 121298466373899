import "../../../styles/protected/navbar/Navbar.css"
import "../../../styles/protected/BaseStyles.css"
import logoLight from "../../../images/logo_light.png"
import { useContext, useEffect, useState } from "react"
import NavbarLinks from "../../../hard_coded/NavbarLinks"
import { GlobalContext } from "../../../hooks/context/GlobalContext"
import { FiMenu } from "react-icons/fi"
import { IoClose } from "react-icons/io5"
import NavbarBurger from "./NavbarBurger"

const Navbar = () => {
    const [navLinks, setNavLinks] = useState(NavbarLinks)
    const [isNavbarFixed, setIsNavbarFixed] = useState(false)
    const { scrollPosition, setScrollPosition } = useContext(GlobalContext)
    const [isSolutionsHover, setIsSolutionsHover] = useState(false)
    const [isResourcesHover, setIsResourcesHover] = useState(false)
    const [isCompanyHover, setIsCompanyHover] = useState(false)
    const [navMenuClicked, setNavMenuClicked] = useState(false)

    const getScrollPosition = () => {
        const pos = window.scrollY
        setScrollPosition(pos)
        if (pos > 1) {
            setIsNavbarFixed(true)
        } else {
            setIsNavbarFixed(false)
        }
    }

    const handleNavLink = (title: string) => {
        // console.log("TITLE: ", title)
        if (title === "Solutions") {
            setIsSolutionsHover(true)
            setIsResourcesHover(false)
            setIsCompanyHover(false)
        } else if (title === "Resources") {
            setIsSolutionsHover(false)
            setIsResourcesHover(true)
            setIsCompanyHover(false)
        } else if (title === "Company") {
            setIsSolutionsHover(false)
            setIsResourcesHover(false)
            setIsCompanyHover(true)
        }
    }

    const handleCloseNavlink = () => {
        setIsSolutionsHover(false)
        setIsResourcesHover(false)
        setIsCompanyHover(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", getScrollPosition)
        return () => {
            window.addEventListener("scroll", getScrollPosition)
        }
    }, [isNavbarFixed])

    const handleNavbar = (page: any) => {
        const pageSelect = document.querySelector(page)
        if (page === "#student-demo" || page === "#teacher-demo") {
            pageSelect.scrollIntoView({ behavior: 'smooth' })

        } else {
            pageSelect.scrollIntoView({ behavior: 'smooth', block: "center" })
        }
    }

    return (
        <div className={isNavbarFixed ? 'navbar-container-fixed' : 'navbar-container'}>

            <div className="logo-box">
                <img src={logoLight} alt="Codaveri Logo" className="logo" />
            </div>

            <div className="link-box">
                <div className="navbar-burger-box">
                    {navMenuClicked ? <IoClose onClick={() => setNavMenuClicked(prev => !prev)} className="medium-icon" style={{ color: "white" }} /> : <FiMenu onClick={() => setNavMenuClicked(prev => !prev)} className="medium-icon" style={{ color: "white" }} />}
                </div>
                {navMenuClicked &&
                    <NavbarBurger />
                }
                {navLinks.map(link => {
                    return (
                        <>

                            <div className='nav-link small-text light-bold' id={link} onMouseOver={() => handleNavLink(link)} onMouseOut={handleCloseNavlink}>{link}</div>

                            {isSolutionsHover &&
                                <div className="solutions-box" onMouseOver={() => setIsSolutionsHover(true)} onMouseOut={() => setIsSolutionsHover(false)}>
                                    <div className="link very-small-text " onClick={() => handleNavbar("#student-demo")}>Students</div>
                                    <div className="link very-small-text " onClick={() => handleNavbar("#teacher-demo")}>Teachers</div>
                                </div>}

                            {isResourcesHover &&
                                <div className="resource-box" onMouseOver={() => setIsResourcesHover(true)} onMouseOut={() => setIsResourcesHover(false)}>
                                    <div className="link very-small-text " onClick={() => handleNavbar("#proven-impact-page")}>Proven Impact</div>
                                    {/* <div className="link very-small-text ">Integration</div> */}
                                </div>}

                            {isCompanyHover &&
                                <div className="company-box" onMouseOver={() => setIsCompanyHover(true)} onMouseOut={() => setIsCompanyHover(false)}>
                                    {/* <div className="link very-small-text ">About Us</div> */}
                                    <div className="link very-small-text " onClick={() => handleNavbar("#leadership-page")}>Leadership</div>
                                    <div className="link very-small-text " onClick={() => handleNavbar("#contact-page")}>Contact</div>
                                </div>}



                        </>
                    )
                })}
            </div>

        </div>
    )
}

export default Navbar