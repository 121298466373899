import { useContext, useEffect, useState } from "react"
import "../../../styles/protected/content/Content.css"
import StudentDemo0 from "./studentDemo/StudentDemo0"
import StudentDemo1 from "./studentDemo/StudentDemo1"
import StudentDemo2 from "./studentDemo/StudentDemo2"
import StudentDemo3 from "./studentDemo/StudentDemo3"
import StudentDemo4 from "./studentDemo/StudentDemo4"
import 'aos/dist/aos.css'
// @ts-ignore
import AOS from 'aos'
import ProvenImpact from "./stats/ProvenImpact"
import Contact from "./contact/Contact"
import LeaderShip from "./leadership/Leadership"
import TeacherDemo0 from "./teacherDemo/TeacherDemo0"
import TeacherDemo1 from "./teacherDemo/TeacherDemo1"
import TeacherDemo2 from "./teacherDemo/TeacherDemo2"
import TeacherDemo3 from "./teacherDemo/TeacherDemo3"
import { GlobalContext } from "../../../hooks/context/GlobalContext"

const Content = () => {

    const { demoBtn1, demoBtn2, demoBtn3, setDemoBtn1, setDemoBtn2, setDemoBtn3 } = useContext(GlobalContext)

    const [scrollPosition, setScrollPosition] = useState(0)
    // const [isCodeFixed, setIsCodeFixed] = useState(false)
    const [studentDemo0, setStudentDemo0] = useState(true)
    const [studentDemo1, setStudentDemo1] = useState(false)
    const [studentDemo2, setStudentDemo2] = useState(false)
    const [studentDemo3, setStudentDemo3] = useState(false)
    const [studentDemo4, setStudentDemo4] = useState(false)

    const [teacherDemo0, setTeacherDemo0] = useState(true)
    const [teacherDemo1, setTeacherDemo1] = useState(false)
    const [teacherDemo2, setTeacherDemo2] = useState(false)
    const [teacherDemo3, setTeacherDemo3] = useState(false)

    const [studentFlag, setStudentFlag] = useState(0)
    const [teacherFlag, setTeacherFlag] = useState(0)

    const getScrollPosition = () => {
        const pos = window.scrollY
        setScrollPosition(pos)
        console.log("SCROLL: ", scrollPosition)
    }

    const studentTimeout = (a: boolean, b: boolean, c: boolean, d: boolean, e: boolean, duration: number) => {
        setTimeout(() => {
            setStudentDemo0(a)
            setStudentDemo1(b)
            setStudentDemo2(c)
            setStudentDemo3(d)
            setStudentDemo4(e)
        }, duration)
    }

    const teacherTimeout = (a: boolean, b: boolean, c: boolean, d: boolean, duration: number) => {
        setTimeout(() => {
            setTeacherDemo0(a)
            setTeacherDemo1(b)
            setTeacherDemo2(c)
            setTeacherDemo3(d)
        }, duration)
    }

    useEffect(() => {
        if (studentFlag === 0) {
            studentTimeout(false, true, false, false, false, 4000)
            studentTimeout(false, false, true, false, false, 17000)
            studentTimeout(false, false, false, true, false, 25000)
            studentTimeout(false, false, false, false, true, 34000)
            setStudentFlag(1)
        } else if (studentFlag === 1) {
            setInterval(() => {
                studentTimeout(true, false, false, false, false, 2000)
                studentTimeout(false, true, false, false, false, 5000)
                studentTimeout(false, false, true, false, false, 18000)
                studentTimeout(false, false, false, true, false, 28000)
                studentTimeout(false, false, false, false, true, 36000)
            }, 38000)
        }

        if (teacherFlag === 0) {
            teacherTimeout(false, true, false, false, 4000)
            teacherTimeout(false, false, true, false, 7000)
            teacherTimeout(false, false, false, true, 31000)
            setTeacherFlag(1)
        } else if (teacherFlag === 1) {
            setInterval(() => {
                teacherTimeout(true, false, false, false, 2000)
                teacherTimeout(false, true, false, false, 6000)
                teacherTimeout(false, false, true, false, 9000)
                teacherTimeout(false, false, false, true, 33000)
            }, 37000)
        }

    }, [studentFlag, teacherFlag])

    // console.log("BTN1: ", demoBtn1)
    // console.log("BTN2: ", demoBtn2)
    // console.log("BTN3: ", demoBtn3)

    return (
        <div className='content-container'>
            <div className="demo-content-box">
                <p className="large-text extra-bold" id="student-demo">Student's Solution</p>
                <p className="medium-small-text light-bold" style={{ color: "#a0abc4" }}>Learning programming can be challenging! Our AI Coach provides real-time personalized assistance to struggling students, resulting in improved learning speed and better concept retention.</p>
                {studentDemo0 && <StudentDemo0 />}
                {studentDemo1 && <StudentDemo1 />}
                {studentDemo2 && <StudentDemo2 />}
                {studentDemo3 && <StudentDemo3 />}
                {studentDemo4 && <StudentDemo4 />}
            </div>
            <div className="demo-content-box">
                <p className="large-text extra-bold" id="teacher-demo">Teacher's Solution</p>
                <p className="medium-small-text light-bold" style={{ color: "#a0abc4" }}>Teaching programming can be demanding! Providing personalized assistance to students can be a tedious and repetitive task.
                    Our AI Coach improves teachers' efficiency by offering instructional and grading support.</p>
                {teacherDemo0 && <TeacherDemo0 />}
                {teacherDemo1 && <TeacherDemo1 />}
                {teacherDemo2 && <TeacherDemo2 />}
                {teacherDemo3 && <TeacherDemo3 />}
            </div>
            <ProvenImpact />
            {/* <LeaderShip /> */}
            {/* <Contact /> */}
        </div>
    )
}

export default Content
