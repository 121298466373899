import "../../../styles/protected/content/TopPart.css"
import 'aos/dist/aos.css';
// @ts-ignore
import AOS from 'aos';
import { useContext, useEffect, useRef, useState } from "react";
import { BiChevronRight } from "react-icons/bi"
import { FaPlay } from "react-icons/fa"
import { IoIosPause } from "react-icons/io"
import { GlobalContext } from "../../../hooks/context/GlobalContext";
// @ts-ignore
import CodaveriVideo from "../../../videos/Codaveri.mp4"

const TopPart = () => {

    const { scrollPosition } = useContext(GlobalContext)
    const [changeHeight, setChangeHeight] = useState(false)
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isMouseOver, setIsMouseOver] = useState(false)
    const [isVideoPlaying, setIsVideoPlaying] = useState(false)
    const [showVideoIconStart, setShowVideoIconStart] = useState(true)

    useEffect(() => {
    }, [isMouseOver])

    // console.log("MOUSE: ", isMouseOver)
    const handlePlayVideo = () => {
        setIsVideoPlaying(true)
        setShowVideoIconStart(false)
        if (videoRef.current !== null)
            videoRef.current.play()
    }

    const handlePauseVideo = () => {
        setIsVideoPlaying(false)
        if (videoRef.current !== null)
            videoRef.current.pause()
    }

    return (
        <div className="top-part-container">
            <div>
                <p className="extra-large-text extra-bold highlight-text">Automated Programming Coach</p>
                <p className="medium-text light-bold" style={{ color: "#a0abc4" }}>
                    Do you teach programming? If so, our AI Coach is the ideal companion to boost your efficiency.
                    With real-time personalized guidance, we can enhance the learning experience for your students and improve teachers' effectiveness.

                </p>
                {/* <div className="free-trial-btn">
                    <div className="small-text light-bold" style={{ color: "whitesmoke" }}>Start free trial</div>
                    <BiChevronRight className="medium-text light-bold right-arrow" />
                </div>  */}
            </div>
            <div>
                {/* muted autoPlay controls loop */}
                <video className="video" ref={videoRef} onMouseOver={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
                    <source src={CodaveriVideo} type="video/mp4" />
                </video>
                {(isMouseOver || showVideoIconStart || !isVideoPlaying) &&
                    <div className="play-video-box" onMouseOver={() => setIsMouseOver(true)}>
                        {!isVideoPlaying ?
                            <FaPlay className="small-icon play-video-icon" onClick={handlePlayVideo} /> :
                            <IoIosPause className="small-icon play-video-icon" onClick={handlePauseVideo} />
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default TopPart