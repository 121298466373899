import "../../../styles/protected/footer/Footer.css"
import logo from "../../../images/logo_light.png"
import { BsLinkedin, BsTwitter } from "react-icons/bs"
import { GrMail } from "react-icons/gr"
import { useEffect, useState } from "react";

const Footer = () => {
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [usernameContact, setUsernameContact] = useState("")
    const [userEmailContact, setUserEmailContact] = useState("")
    const [userMessageContact, setUserMessageContact] = useState("")

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        // console.log("Width: ", windowSize)
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    return (
        <>

            {windowSize[0] <= 960 &&
                <div className='footer-container-mobile'>
                    <div className="footer-contact-box-mobile">
                        <div><p className="small-text light-bold" style={{ color: "white" }}>Get in touch!</p></div>
                        <div>
                            <div><input type="text" placeholder="Name" className="contact-input" value={usernameContact} onChange={e => setUsernameContact(e.target.value)} /></div>
                            <div><input type="text" placeholder="Email" className="contact-input" value={userEmailContact} onChange={e => setUserEmailContact(e.target.value)} /></div>
                        </div>
                        <div>
                            <div><textarea className="contact-input-large" placeholder="What would you like to contact us about?" value={userMessageContact} onChange={e => setUserMessageContact(e.target.value)} /></div>
                        </div>
                        <a href={`mailto:contact@codaveri.com?subject=Greetings from ${usernameContact}&body=${userMessageContact}`} ><div className="footer-send-msg-btn very-small-text">Send Message</div></a>
                    </div>

                    <div className="footer-links-container-mobile">

                        <div className="footer-links-box">
                            <div className="footer-logo-box">
                                <img src={logo} alt="Codaveri Logo" className="footer-logo" />
                            </div>
                            <a href={`mailto:contact@codaveri.com`} className="contact-link">
                                <GrMail className="very-small-icon contact-icon" style={{ fontSize: "1.2rem" }} />
                                <p className="very-small-text">contact@codaveri.com</p>
                            </a>
                            <div className="footer-links">
                                <div>
                                    <p className="very-small-text light-bold" style={{ color: "white" }}>Solutions</p>
                                    <div>
                                        <p className="very-small-text light-bold" >Students</p>
                                        <p className="very-small-text light-bold" >Teachers</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="very-small-text light-bold" style={{ color: "white" }}>Resources</p>
                                    <div>
                                        <p className="very-small-text light-bold" >Proven Impact</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="very-small-text light-bold" style={{ color: "white" }}>Company</p>
                                    <div>
                                        <p className="very-small-text light-bold" >Leadership</p>
                                        <p className="very-small-text light-bold" >Contact</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-line"></div>

                        <div className="footer-copyrights-box">
                            <div>
                                <p className="very-small-text" style={{ color: "#a0abc4" }}>©2023 Codaveri. All Rights Reserved</p>
                            </div>
                            <div>
                                {/* <GrMail className="very-small-icon footer-icon linkedin" /> */}

                                <BsLinkedin className="very-small-icon footer-icon linkedin" />
                                <BsTwitter className="very-small-icon footer-icon twitter" />
                            </div>
                        </div>

                    </div>
                </div>


            }

            {windowSize[0] > 960 &&
                <div className='footer-container'>
                    <div className="footer-links-container">

                        <div className="footer-links-box">
                            <div className="footer-logo-box">
                                <img src={logo} alt="Codaveri Logo" className="footer-logo" />
                            </div>
                            <a href={`mailto:contact@codaveri.com`} className="contact-link">
                                <GrMail className="very-small-icon contact-icon" />
                                <p className="very-small-text">contact@codaveri.com</p>
                            </a>
                            <div className="footer-links">
                                <div>
                                    <p className="very-small-text light-bold" style={{ color: "white" }}>Solutions</p>
                                    <div>
                                        <p className="very-small-text light-bold">Students</p>
                                        <p className="very-small-text light-bold">Teachers</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="very-small-text light-bold" style={{ color: "white" }}>Resources</p>
                                    <div>
                                        <p className="very-small-text light-bold">Proven Impact</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="very-small-text light-bold" style={{ color: "white" }}>Company</p>
                                    <div>
                                        <p className="very-small-text light-bold">Leadership</p>
                                        <p className="very-small-text light-bold">Contact</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="footer-line"></div>

                        <div className="footer-copyrights-box">
                            <div>
                                <p className="very-small-text" style={{ color: "#a0abc4" }}>©2023 Codaveri. All Rights Reserved</p>
                            </div>

                            <div>
                                {/* <GrMail className="very-small-icon footer-icon linkedin" /> */}
                                <BsLinkedin className="very-small-icon footer-icon linkedin" />
                                <BsTwitter className="very-small-icon footer-icon twitter" />
                            </div>
                        </div>

                    </div>

                    <div className="footer-contact-box">
                        <div><p className="small-text light-bold">Get in touch!</p></div>
                        <div>
                            <div><input type="text" placeholder="Name" className="contact-input" value={usernameContact} onChange={e => setUsernameContact(e.target.value)} /></div>
                            <div><input type="text" placeholder="Email" className="contact-input" value={userEmailContact} onChange={e => setUserEmailContact(e.target.value)} /></div>
                        </div>
                        <div>
                            <div><textarea className="contact-input-large" placeholder="What would you like to contact us about?" value={userMessageContact} onChange={e => setUserMessageContact(e.target.value)} /></div>
                        </div>
                        <a href={`mailto:contact@codaveri.com?subject=Greetings from ${usernameContact}&body=${userMessageContact}`}><div className="footer-send-msg-btn very-small-text">Send Message</div></a>
                    </div>
                </div>
            }


        </>
    )
}

export default Footer