import Content from "./components/protected/content/Content";
import Footer from "./components/protected/footer/Footer";
import Navbar from "./components/protected/navbar/Navbar";
import "./styles/App.css"
// @ts-ignore
import ScrollMagic from 'scrollmagic';
// @ts-ignore
import { Controller, Scene } from 'scrollmagic';
import { useEffect, useState } from "react";
import TopPart from "./components/protected/content/TopPart";
import { GlobalContext } from "./hooks/context/GlobalContext";

function App() {

  const [scrollPosition, setScrollPosition] = useState(0)
  const [demoBtn1, setDemoBtn1] = useState(true)
  const [demoBtn2, setDemoBtn2] = useState(false)
  const [demoBtn3, setDemoBtn3] = useState(false)

  return (
    <GlobalContext.Provider value={{ scrollPosition, setScrollPosition, demoBtn1, setDemoBtn1, demoBtn2, setDemoBtn2, demoBtn3, setDemoBtn3 }}>
      <div className="app">
        <Navbar />
        <TopPart />
        <Content />
        <Footer />
      </div>
    </GlobalContext.Provider>
  )


}

export default App;

