import "../../../../styles/protected/content/StudentDemo.css"
import "../../../../styles/protected/BaseStyles.css"
import { FaLaptopCode, FaRegLightbulb, FaTools } from "react-icons/fa"
import { BiBot, BiChevronRight } from "react-icons/bi"
import { MdVerified } from "react-icons/md"
import { useEffect, useState, useContext } from "react"
import { GlobalContext } from "../../../../hooks/context/GlobalContext"

const StudentDemo2 = () => {
    const { demoBtn1, demoBtn2, demoBtn3, setDemoBtn1, setDemoBtn2, setDemoBtn3 } = useContext(GlobalContext)
    const [fixBtnClicked, setFixedBtnClicked] = useState(false)
    const [showLinePointer, setShowLinePointer] = useState(false)

    useEffect(() => {

        // setDummy(prev => !prev)
        const pointerTimeout = setTimeout(() => {
            setShowLinePointer(true)
        }, 1)

        const timeout = setTimeout(() => {
            setFixedBtnClicked(true)
        }, 6000)
        // console.log("FIX: ", fixBtnClicked)
        return () => {
            clearTimeout(timeout)
            clearTimeout(pointerTimeout)
        }

    }, [fixBtnClicked])

    const lineNums = []
    for (let i = 1; i <= 15; i++) {
        lineNums.push(i)
    }

    const handleDemoIcon = (a: boolean, b: boolean, c: boolean) => {
        // setDemoBtn1(a)
        // setDemoBtn2(b)
        // setDemoBtn3(c)
    }
    return (
        <div className='student-demo-container'>

            <div className="icons-box">
                <div className="line-marker">
                    <div className="icon-btn" ><FaLaptopCode className="icon medium-icon" style={{ color: "#FF6363" }} onClick={() => handleDemoIcon(true, false, false)} /></div>
                    <p className="icon-title very-small-text light-bold">Incorrect Program</p>
                </div>
                <div className="line-marker">
                    <div className="icon-btn" style={{ boxShadow: "3px 3px 80px rgba(99, 245, 255, 1), -3px -3px 80px rgba(99, 245, 255, 1)" }}><BiBot className="icon medium-icon" style={{ color: "#33DAFF" }} onClick={() => handleDemoIcon(false, true, false)} /></div>
                    <p className="icon-title icon-title-highlight very-small-text light-bold" >Automated Hint</p>
                </div>
                <div className="line-marker">
                    <div className="icon-btn" ><MdVerified className="icon medium-icon" style={{ color: "#33FF6C" }} /></div>
                    <p className="icon-title very-small-text light-bold" >Successful Submission</p>
                </div>
            </div>

            <div className="code-box">
                <div className="student-code-headers">
                    <p className="small-text" style={{ color: "#a0abc4" }}>Loops</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <div className="student-header small-text light-bold" style={{ color: "white" }}>Prime Number</div>
                </div>
                <div className="student-code">
                    <div className="code-line-numbers">
                        {lineNums.map(num => {
                            return (
                                <div className="line-numbers line-number-mobile small-text light-bold" style={{ color: "#6E7681" }}>{num}</div>
                            )
                        })}
                        <div className="hint-line-icon" ><FaRegLightbulb className="very-small-icon" /></div>
                        <div className="hint-line" ></div>
                        {showLinePointer && <div className="hint-line-pointer"  ></div>}
                        <div className="hint-box">
                            <div className="small-text hint-msg-header light-bold"><FaRegLightbulb className="small-icon hint-icon" style={{ color: "#FFE039" }} /> Hint</div>
                            <p className="small-text hint-msg-line extra-bold" style={{ color: "#FF6363" }}>Line 10:</p>
                            <p className="small-text hint-msg light-bold">You are computing the remainder incorrectly</p>
                            {fixBtnClicked ?
                                <div className="apply-fix-box student-fix-clicked">
                                    <FaTools className="very-small-icon" />
                                    <p style={{ color: "white" }}>Apply Fix</p>
                                </div> :
                                <div className="apply-fix-box">
                                    <FaTools className="very-small-icon" />
                                    <p style={{ color: "white" }}>Apply Fix</p>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="code">
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># Write a python program that takes N as input:</p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns Yes if number is prime </p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns No otherwise </p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <p className="line-numbers small-text light-bold"><span style={{ color: "#FF6E4F" }}>def</span> <span style={{ color: "#C7A8F2" }}>is_prime</span>(n):</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp; <span style={{ color: "#FF6E4F" }}>if</span> n <span style={{ color: "#3CD6EB" }}>== 1</span>:</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> "No"</p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>for</span> i in <span style={{ color: "#FF6E4F" }}>range</span> (<span style={{ color: "#3CD6EB" }}>2</span>, n):</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}></span>if n<span style={{ color: "#3CD6EB" }}> / </span>i <span style={{ color: "#3CD6EB" }}>==</span> 0:</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “No”</p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “Yes”</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentDemo2