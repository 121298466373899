import "../../../../styles/protected/content/StudentDemo.css"
import "../../../../styles/protected/BaseStyles.css"
import { FaLaptopCode, FaRegLightbulb } from "react-icons/fa"
import { BiBot, BiChevronRight } from "react-icons/bi"
import { HiArrowNarrowLeft } from "react-icons/hi"
import { MdVerified } from "react-icons/md"
import 'aos/dist/aos.css';
// @ts-ignore
import AOS from 'aos';
import { useContext, useEffect, useState } from "react"
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';
import { GlobalContext } from "../../../../hooks/context/GlobalContext"

const StudentDemo0 = () => {

    const { demoBtn1, demoBtn2, demoBtn3, setDemoBtn1, setDemoBtn2, setDemoBtn3 } = useContext(GlobalContext)

    const lineNums = []
    for (let i = 1; i <= 15; i++) {
        lineNums.push(i)
    }
    const handleDemoIcon = (a: boolean, b: boolean, c: boolean) => {
        // setDemoBtn1(a)
        // setDemoBtn2(b)
        // setDemoBtn3(c)
    }



    return (
        <div className='student-demo-container'  >

            <div className="icons-box">
                <div className="line-marker">
                    <div className="icon-btn"><FaLaptopCode className="icon medium-icon" style={{ color: "#FF6363" }} /></div>
                    <p className="icon-title very-small-text light-bold">Incorrect Program</p>
                </div>

                <div className="line-marker">
                    <div className="icon-btn"><BiBot className="icon medium-icon" style={{ color: "#33DAFF" }} /></div>
                    <p className="icon-title very-small-text light-bold">Automated Hint</p>
                </div>

                <div className="line-marker">
                    <div className="icon-btn" ><MdVerified className="icon medium-icon" style={{ color: "#33FF6C" }} /></div>
                    <p className="icon-title very-small-text light-bold">Successful Submission</p>
                </div>
            </div>

            <div className="code-box">
                <div className="student-code-headers">
                    <p className="small-text" style={{ color: "#a0abc4" }}>Loops</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <div className="student-header small-text light-bold" style={{ color: "white" }}>Prime Number</div>
                </div>
                <div className="student-code">
                    <div className="code-line-numbers">
                        {lineNums.map(num => {
                            return (
                                <div className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}>{num}</div>
                            )
                        })}
                    </div>
                    <div className="code">
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># Write a python program that takes N as input:</p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns Yes if the number is prime </p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns No otherwise </p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>

                        {/* <div className="poblem-stmt-pointer" data-aos="fade-left" data-aos-once="false" data-aos-delay="1000">
                            <HiArrowNarrowLeft className="large-icon icon-space " />
                            <p className="medium-text light-bold">Problem Statement</p>
                        </div>
                        <div className="code-pointer" data-aos="fade-left" data-aos-once="false" data-aos-delay="3000">
                            <HiArrowNarrowLeft className="large-icon icon-space" />
                            <p className="medium-text light-bold">Student Incorrect Solution</p>
                        </div> */}

                        <div className="line-numbers small-text light-bold student-solution-box"></div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentDemo0