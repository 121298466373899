import "../../../../styles/protected/content/MessageComponent.css"

interface MessgaeProps {
    img: any
    name: String
    time: String
    msg: any
}

const MessageComponent = ({ img, name, time, msg }: MessgaeProps) => {
    // console.log("MSG: ", msg);
    return (
        <div className="message-component-box">
            <div>
                <img src={img} alt="user pic" className="user-pic" />
                <p className="user-name">{name}</p>
                <p className="user-time">{time}</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start" }}>
                {msg.length > 1 ?
                    <>
                        {/* <p className="user-msg">{msg[0]}<br /><br /><span className="line10">{msg[1]}</span><br />{msg[2]}<br /><br /><span className="fix">{msg[3]}</span><br /><span className="fix-msg">{msg[4]}</span></p> */}
                        <p className="user-msg">{msg[0]}</p>
                        <p className="user-msg" style={{ color: "#FF6363", fontWeight: "500", marginTop: "10px" }}>{msg[1]}</p>
                        <p className="user-msg" >{msg[2]}</p>
                        <p className="user-msg" style={{ color: "#58ff87", fontWeight: "500", marginTop: "10px" }}>{msg[3]}</p>
                        <p className="user-msg fix-msg">{msg[4]}</p>
                    </>
                    :
                    <p className="user-msg">{msg[0]}</p>
                }
            </div>
        </div>
    )
}

export default MessageComponent