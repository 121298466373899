import "../../../../styles/protected/content/TeacherDemo.css"
import "../../../../styles/protected/content/TeacherDemo2.css"
import { FaLaptopCode, FaRegLightbulb, FaTools } from "react-icons/fa"
import { BiBot, BiChevronRight } from "react-icons/bi"
import { RiCloseCircleFill } from "react-icons/ri"
import { HiPencil } from "react-icons/hi"
import { MdVerified, MdOutlineDownloadDone } from "react-icons/md"
import "../../../../styles/protected/content/StudentDemo.css"
import { useEffect, useState } from "react"
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';
import "../../../../styles/protected/content/TeacherDemo3.css"
import 'aos/dist/aos.css';
// @ts-ignore
import AOS from 'aos';

const TeacherDemo2 = () => {
    const [showLinePointer, setShowLinePointer] = useState(false)
    const [lineBulb, setLineBulb] = useState(false)
    const [hintLine, setHintLine] = useState(false)
    const [hintBox, setHintBox] = useState(false)

    const [failedMessage, setFailedMessage] = useState(false)
    // const [showHint, setShowHint] = useState(false)
    const [editBtnClicked, setEditBtnClicked] = useState(false)
    const [hoverEditBtn, setHoverEditBtn] = useState(false)
    const [postBtnClicked, setPostBtnClicked] = useState(false)

    useEffect(() => {
        const pointerTimeout = setTimeout(() => {
            setShowLinePointer(true)
        }, 4000)

        const timeout1 = setTimeout(() => {
            setFailedMessage(true)
        }, 1000)

        const timeout2 = setTimeout(() => {
            setLineBulb(true)
        }, 2500)

        const timeout3 = setTimeout(() => {
            setHintLine(true)
            setHintBox(true)
        }, 4000)

        const timeout4 = setTimeout(() => {
            setHoverEditBtn(true)
        }, 10000)

        const timeout5 = setTimeout(() => {
            setHoverEditBtn(false)
            setEditBtnClicked(true)
        }, 12000)

        let editor = document.querySelector(".teacher-custom-msg")

        const typingEffectDelay = setTimeout(() => {
            let typewriter = new Typewriter(editor, {
                delay: 40
            })

            typewriter
                .pauseFor(500)
                .typeString('Read up more about arithmetic operators John!<br/>')
                // .typeString('<br />#Line 10:<br/>You are computing the remainder incorrectly. ')
                // .typeString('<br />Use a modulo operator instead of the division operator.')
                // .typeString('<br />')
                // .typeString('<br/><span style="">#Fix:<br/>`n % i == 0`</span>')
                .start()
        }, 2500)

        const timeout6 = setTimeout(() => {
            // setEditBtnClicked(false)
            setPostBtnClicked(true)
        }, 20000)

        return () => {
            clearTimeout(timeout1)
            clearTimeout(timeout2)
            clearTimeout(timeout3)
            clearTimeout(timeout4)
            clearTimeout(timeout5)
            clearTimeout(typingEffectDelay)
            clearTimeout(pointerTimeout)
        }

    }, [editBtnClicked])

    const lineNums = []
    for (let i = 1; i <= 15; i++) {
        lineNums.push(i)
    }

    return (
        <div className="teacher-demo-container">
            <div className="icons-box">
                <div className="line-marker">
                    <div className="icon-btn" ><FaLaptopCode className="icon medium-icon" style={{ color: "#FF6363" }} /></div>
                    <p className="icon-title very-small-text light-bold">Incorrect Program</p>
                </div>
                <div className="line-marker">
                    <div className="icon-btn" style={{ boxShadow: "3px 3px 80px rgba(99, 245, 255, 1), -3px -3px 80px rgba(99, 245, 255, 1)" }}><BiBot className="icon medium-icon" style={{ color: "#33DAFF" }} /></div>
                    <p className="icon-title very-small-text light-bold" >Automated Hint</p>
                </div>
                <div className="line-marker">
                    <div className="icon-btn" ><MdVerified className="icon medium-icon" style={{ color: "#33FF6C" }} /></div>
                    <p className="icon-title very-small-text light-bold" >Query Resolved</p>
                </div>
            </div>

            <div className="code-box-teacher" style={{ flexDirection: "column" }}>
                <div className="folder-box">
                    <p className="small-text" style={{ color: "#a0abc4" }}>Loops</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <p className="small-text" style={{ color: "#a0abc4" }}>Prime Number</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <p className="small-text" style={{ color: "white" }}>John's code</p>
                </div>

                <div className="code-box" style={{ position: "relative", border: "0px" }}>
                    <div className="student-code" style={{ border: "0px", borderTopRightRadius: "0px" }}>
                        <div className="code-line-numbers">
                            {lineNums.map(num => {
                                return (
                                    <div className="line-numbers teacher-line-numbers small-text light-bold" style={{ color: "#6E7681" }}>{num}</div>
                                )
                            })}
                            {lineBulb && <div className="hint-line-icon teacher-hint-line-icon" ><FaRegLightbulb /></div>}
                            {hintLine && <div className="hint-line teacher-hint-line"></div>}
                            {showLinePointer && <div className="hint-line-pointer teacher-line-pointer"  ></div>}
                            {hintBox &&
                                <div className="hint-box teacher-edit-hint-box">
                                    <div className="small-text hint-msg-header light-bold"><FaRegLightbulb className="very-small-icon hint-icon" style={{ color: "#FFE039" }} /> Hint</div>
                                    {editBtnClicked ?
                                        <div className="small-text teacher-custom-msg-box" style={{ color: "#dfe4f0" }}>
                                            <p className="small-text teacher-custom-msg" style={{ color: "#dfe4f0" }}></p>
                                            <p className="small-text hint-msg-line" style={{ color: "#dfe4f0" }}>#Line 10:</p>
                                            <p className="small-text hint-msg" style={{ color: "#dfe4f0" }}>You are computing the remainder incorrectly. Use a modulo operator instead of the division operator.</p>
                                            <p className="small-text light-bold" style={{ marginTop: "20px", color: "#dfe4f0" }}>#Fix:</p>
                                            <div className="small-text" style={{ fontFamily: 'Source Code Pro', color: "#dfe4f0" }}>`n % i == 0`</div>
                                        </div> :
                                        <>
                                            <p className="small-text hint-msg-line extra-bold" style={{ color: "#FF6363" }}>Line 10:</p>
                                            <p className="small-text hint-msg light-bold" style={{ color: "#dfe4f0" }}>You are computing the remainder incorrectly.  Use a modulo operator instead of the division operator.</p>
                                            <p className="small-text light-bold" style={{ marginTop: "25px", color: "#58ff87" }}>Fix:</p>
                                            <div className="small-text light-bold fix-code-line" style={{ color: "#dfe4f0", fontFamily: 'Source Code Pro' }}>n % i == 0</div>
                                        </>
                                    }

                                    {!hoverEditBtn && !editBtnClicked &&
                                        <div className="edit-post-box">
                                            <div className="apply-fix-box">
                                                <HiPencil style={{ fontSize: "1.2rem" }} />
                                                <p style={{ color: "white" }}>Edit Hint</p>
                                            </div>
                                            <div className="apply-fix-box post-hint-btn">
                                                <MdOutlineDownloadDone style={{ fontSize: "1.2rem" }} />
                                                <p style={{ color: "white" }}>Post</p>
                                            </div>
                                        </div>
                                    }

                                    {hoverEditBtn && !editBtnClicked &&
                                        <div className="edit-post-box">
                                            <div className="apply-fix-box-clicked">
                                                <HiPencil style={{ fontSize: "1.2rem" }} />
                                                <p style={{ color: "white" }}>Edit Hint</p>
                                            </div>
                                            <div className="apply-fix-box post-hint-btn">
                                                <MdOutlineDownloadDone style={{ fontSize: "1.2rem" }} />
                                                <p style={{ color: "white" }}>Post</p>
                                            </div>
                                        </div>
                                    }

                                    {editBtnClicked &&
                                        <div className="edit-post-box">
                                            <div className={postBtnClicked ? "apply-fix-box post-hint-btn-clicked" : "apply-fix-box post-hint-btn"}>
                                                <MdOutlineDownloadDone style={{ fontSize: "1.2rem" }} />
                                                <p style={{ color: "white" }}>Post</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="code" style={{ border: "0px" }}>
                            <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># Write a python program that takes as input a number N and</p>
                            <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns Yes if the number is prime </p>
                            <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns No otherwise </p>
                            <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                            <p className="line-numbers small-text light-bold"><span style={{ color: "#FF6E4F" }}>def</span> <span style={{ color: "#C7A8F2" }}>is_prime</span>(n):</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp; <span style={{ color: "#FF6E4F" }}>if</span> n <span style={{ color: "#3CD6EB" }}>== 1</span>:</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> "No"</p>
                            <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>for</span> i in <span style={{ color: "#FF6E4F" }}>range</span> (<span style={{ color: "#3CD6EB" }}>2</span>, n):</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}></span>if n<span style={{ color: "#3CD6EB" }}> / </span>i <span style={{ color: "#3CD6EB" }}>==</span> 0:</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “No”</p>
                            <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “Yes”</p>
                        </div>
                    </div>

                    {/* {failedMessage &&
                        <div className="failed-box-teacher">
                            <div className="failed-msg-box" >
                                <RiCloseCircleFill className="small-icon" style={{ color: "#FF6363" }} />
                                <p className="small-text" style={{ color: "#FF6363" }}>Incorrect Program</p>
                            </div>
                        </div>
                    } */}


                </div>
            </div>
        </div >
    )
}

export default TeacherDemo2