import "../../../../styles/protected/content/StudentDemo.css"
import "../../../../styles/protected/BaseStyles.css"
import { FaLaptopCode, FaRegLightbulb } from "react-icons/fa"
import { VscPassFilled } from "react-icons/vsc"
import { BiBot, BiChevronRight } from "react-icons/bi"
import { MdVerified } from "react-icons/md"
import { useEffect, useState } from "react"
import { ColorRing } from 'react-loader-spinner'

const StudentDemo4 = () => {
    const [startLoader, setStartLoader] = useState(false)
    const [showCorrectCodeMsg, setShowCorrectCodeMsg] = useState(false)
    const [successBtnShadow, setSuccessBtnShadow] = useState(true)

    useEffect(() => {
        const loadingAnimate = setTimeout(() => {
            setStartLoader(true)
        }, 1)

        const wrongCodeMsg = setTimeout(() => {
            setStartLoader(false)
            setShowCorrectCodeMsg(true)
            setSuccessBtnShadow(false)
        }, 2000)

        return () => {
            clearTimeout(loadingAnimate)
            clearTimeout(wrongCodeMsg)
        }
    }, [])

    const lineNums = []
    for (let i = 1; i <= 15; i++) {
        lineNums.push(i)
    }
    return (
        <div className='student-demo-container'>

            <div className="icons-box">
                <div className="line-marker">
                    <div className="icon-btn"><FaLaptopCode className="icon medium-icon" style={{ color: "#FF6363" }} /></div>
                    <p className="icon-title very-small-text light-bold">Incorrect Program</p>
                </div>

                <div className="line-marker">
                    <div className="icon-btn"><BiBot className="icon medium-icon" style={{ color: "#33DAFF" }} /></div>
                    <p className="icon-title very-small-text light-bold">Automated Hint</p>
                </div>

                <div className="line-marker">
                    <div className="icon-btn" style={{ boxShadow: successBtnShadow ? "3px 3px 80px rgba(99, 255, 99, 1), -3px -3px 80px rgba(99, 255, 99, 1)" : "" }}><MdVerified className="icon medium-icon" style={{ color: "#33FF6C" }} /></div>
                    <p className="icon-title very-small-text light-bold">Successful Submission</p>

                </div>
            </div>

            <div className="code-box">
                <div className="student-code-headers">
                    <p className="small-text" style={{ color: "#a0abc4" }}>Loops</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <div className="student-header small-text light-bold" style={{ color: "white" }}>Prime Number</div>
                </div>
                <div className="student-code">
                    <div className="code-line-numbers">
                        {lineNums.map(num => {
                            return (
                                <div className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}>{num}</div>
                            )
                        })}
                    </div>
                    <div className="code">
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># Write a python program that takes as input a number N and</p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns Yes if the number is prime </p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns No otherwise </p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <p className="line-numbers small-text light-bold"><span style={{ color: "#FF6E4F" }}>def</span> <span style={{ color: "#C7A8F2" }}>is_prime</span>(n):</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp; <span style={{ color: "#FF6E4F" }}>if</span> n <span style={{ color: "#3CD6EB" }}>== 1</span>:</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> "No"</p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>for</span> i in <span style={{ color: "#FF6E4F" }}>range</span> (<span style={{ color: "#3CD6EB" }}>2</span>, n):</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}></span>if n<span style={{ color: "#3CD6EB" }}> % </span>i <span style={{ color: "#3CD6EB" }}>==</span> 0:</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “No”</p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                        <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “Yes”</p>
                    </div>
                    {startLoader &&
                        <div className="loading-box">
                            <ColorRing
                                visible={true}
                                height="50"
                                width="50"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#4CBFED', '#4CBFED', '#4CBFED', '#4CBFED', '#4CBFED']}

                            />
                            <p className="small-text">Running code</p>
                        </div>
                    }
                    {showCorrectCodeMsg &&
                        <div className="failed-box">
                            <div className="failed-msg-box">
                                <VscPassFilled className="small-icon" style={{ color: "#33ff6c" }} />
                                <p className="small-text light-bold" style={{ color: "#33ff6c" }}>Program Passed</p>
                            </div>
                            <div className="show-hint-box" style={{ border: "1px solid #33ff6c", cursor: "auto", pointerEvents: "none" }}>
                                {/* <FaLightbulb className="very-small-icon bulb-icon" /> */}
                                <p className="very-small-text light-bold" style={{ margin: "0" }}>Submit Assignment</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default StudentDemo4