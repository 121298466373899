import "../../../../styles/protected/content/StudentDemo.css"
import "../../../../styles/protected/BaseStyles.css"
import { FaLaptopCode, FaLightbulb } from "react-icons/fa"
import { RiCloseCircleFill } from "react-icons/ri"
import { BiBot, BiChevronRight } from "react-icons/bi"
import { MdVerified } from "react-icons/md"
import { ColorRing } from 'react-loader-spinner'
import { useEffect, useState, useContext } from "react"
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';
import { GlobalContext } from "../../../../hooks/context/GlobalContext"

const StudentDemo1 = () => {
    const { demoBtn1, demoBtn2, demoBtn3, setDemoBtn1, setDemoBtn2, setDemoBtn3 } = useContext(GlobalContext)
    const lineNums = []
    for (let i = 1; i <= 15; i++) {
        lineNums.push(i)
    }

    const [startLoader, setStartLoader] = useState(false)
    const [showWrongCodeMsg, setShowWrongCodeMsg] = useState(false)
    const [hintBtnClicked, setHintBtnClicked] = useState(false)

    // const loader = () => {
    //     return (
    //         <ColorRing
    //             visible={true}
    //             height="80"
    //             width="80"
    //             ariaLabel="blocks-loading"
    //             wrapperStyle={{}}
    //             wrapperClass="blocks-wrapper"
    //             colors={['#b8c480', '#B2A3B5', '#F4442E', '#51E5FF', '#429EA6']}
    //         />
    //     )
    // }

    const handleDemoIcon = (a: boolean, b: boolean, c: boolean) => {
        // setDemoBtn1(a)
        // setDemoBtn2(b)
        // setDemoBtn3(c)
    }

    useEffect(() => {



        let editor = document.querySelector(".student-solution-box")

        const typingEffectDelay = setTimeout(() => {
            let typewriter = new Typewriter(editor, {
                delay: 10
            })

            typewriter
                .pauseFor(500)
                .typeString('<span style="color: #FF6E4F">def</span> <span style="color: #C7A8F2">is_prime</span>(n):')
                .typeString('<br />&nbsp;&nbsp;&nbsp; <span style="color: #FF6E4F">if</span> n <span style="color: #3CD6EB">== 1</span>:')
                .typeString('<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style="color: #FF6E4F">return</span> "No"')
                .typeString('<br />')
                .typeString('<br />&nbsp;&nbsp;&nbsp;  <span style="color: #FF6E4F">for</span> i in <span style="color: #FF6E4F">range</span> (<span style="color: #3CD6EB">2</span>, n):')
                .typeString('<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style="color: #FF6E4F"></span>if n<span style="color: #3CD6EB"> / </span>i <span style="color: #3CD6EB">==</span> 0:')
                .typeString('<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style="color: #FF6E4F">return</span> “No”')
                .typeString('<br />')
                .typeString('<br/>&nbsp;&nbsp;&nbsp;  <span style="color: #FF6E4F">return</span> “Yes”')
                .start()
        }, 2000)

        const loadingAnimate = setTimeout(() => {
            setStartLoader(true)
        }, 7000)

        const wrongCodeMsg = setTimeout(() => {
            setStartLoader(false)
            setShowWrongCodeMsg(true)
        }, 9000)

        const hintBtnHighlight = setTimeout(() => {
            setHintBtnClicked(true)
        }, 11000)

        return () => {
            clearTimeout(loadingAnimate)
            clearTimeout(typingEffectDelay)
            clearTimeout(wrongCodeMsg)
        }
    }, [])
    return (
        <div className='student-demo-container'>

            <div className="icons-box">
                <div className="line-marker">
                    <div className="icon-btn" style={{ boxShadow: "3px 3px 80px rgba(255, 99, 99, 1), -3px -3px 80px rgba(255, 99, 99, 1)" }}><FaLaptopCode className="icon medium-icon" style={{ color: "#FF6363" }} onClick={() => handleDemoIcon(true, false, false)} /></div>
                    <p className="icon-title very-small-text light-bold">Incorrect Program</p>
                </div>
                <div className="line-marker">
                    <div className="icon-btn"><BiBot className="icon medium-icon" style={{ color: "#33DAFF" }} /></div>
                    <p className="icon-title very-small-text light-bold">Automated Hint</p>
                </div>

                <div className="line-marker">
                    <div className="icon-btn" ><MdVerified className="icon medium-icon" style={{ color: "#33FF6C" }} /></div>
                    <p className="icon-title very-small-text light-bold" >Successful Submission</p>
                </div>
            </div>

            <div className="code-box">
                <div className="student-code-headers">
                    <p className="small-text" style={{ color: "#a0abc4" }}>Loops</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <div className="student-header small-text light-bold" style={{ color: "white" }}>Prime Number</div>
                </div>
                <div className="student-code">
                    <div className="code-line-numbers">
                        {lineNums.map(num => {
                            return (
                                <div className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}>{num}</div>
                            )
                        })}
                    </div>
                    <div className="code">
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># Write a python program that takes N as input:</p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns Yes if the number is prime </p>
                        <p className="line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns No otherwise </p>
                        <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>

                        <div className="line-numbers small-text light-bold student-solution-box"></div>


                        {/* <div className="poblem-stmt-pointer" data-aos="fade-left" data-aos-once="false" data-aos-delay="1000">
                            <RiCloseCircleFill />
                        </div> */}
                        {/* <div className="code-pointer" data-aos="fade-left" data-aos-once="false" data-aos-delay="3000">
                            <p className="medium-text light-bold">Student Incorrect Solution</p>
                        </div> */}
                    </div>
                    {startLoader &&
                        <div className="loading-box">
                            <ColorRing
                                visible={true}
                                height="50"
                                width="50"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#4CBFED', '#4CBFED', '#4CBFED', '#4CBFED', '#4CBFED']}

                            />
                            <p className="small-text">Running code</p>
                        </div>
                    }
                    {showWrongCodeMsg &&
                        <div className="failed-box">
                            <div className="failed-msg-box">
                                <RiCloseCircleFill className="small-icon" style={{ color: "#FF6363" }} />
                                <p className="small-text light-bold" style={{ color: "#FF6363" }}>Program Failed</p>
                            </div>
                            {hintBtnClicked ?
                                <div className="show-hint-box student-hint-clicked">
                                    <FaLightbulb className="very-small-icon bulb-icon" />
                                    <p className="very-small-text light-bold" style={{ color: "white" }}>Show Hints</p>
                                </div> :
                                <div className="show-hint-box">
                                    <FaLightbulb className="very-small-icon bulb-icon" />
                                    <p className="very-small-text light-bold">Show Hints</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default StudentDemo1