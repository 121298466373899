import "../../../../styles/protected/content/TeacherDemo.css"
import { FaLaptopCode, FaRegLightbulb } from "react-icons/fa"
import { BiBot } from "react-icons/bi"
import { MdVerified } from "react-icons/md"

const TeacherDemo0 = () => {
    const studentList = [
        {
            studentId: "01",
            studentName: "John",
            status: "Incorrect",
            color: "#FF6363"
        },
        {
            studentId: "02",
            studentName: "Neha",
            status: "Completed",
            color: "#33FF6C"
        },
        {
            studentId: "03",
            studentName: "Rohan",
            status: "Not Attempted",
            color: "#ffb85c"
        },
        {
            studentId: "04",
            studentName: "Susan",
            status: "Completed",
            color: "#33FF6C"
        }
    ]

    return (
        <div className="teacher-demo-container">

            {/* Hover options */}
            <div className="icons-box">
                <div className="line-marker">
                    <div className="icon-btn"><FaLaptopCode className="icon medium-icon" style={{ color: "#FF6363" }} /></div>
                    <p className="icon-title very-small-text light-bold">Incorrect Program</p>

                </div>
                <div className="line-marker">
                    <div className="icon-btn"><BiBot className="icon medium-icon" style={{ color: "#33DAFF" }} /></div>
                    <p className="icon-title very-small-text light-bold">Automated Hint</p>
                </div>
                <div className="line-marker">
                    <div className="icon-btn" ><MdVerified className="icon medium-icon" style={{ color: "#33FF6C" }} /></div>
                    <p className="icon-title very-small-text light-bold" >Query Resolved</p>
                </div>
            </div>

            {/* Demp Part */}
            <div className="code-box-teacher">

                <div className="topics-box">
                    <div className="box-header medium-text light-bold" style={{ textAlign: "center" }}>Topics</div>
                    <div className="topic small-text light-bold">1. Expressions</div>
                    <div className="topic small-text light-bold">2. Conditionals</div>
                    <div className="topic small-text light-bold">3. Loops</div>
                    <div className="topic small-text light-bold">4. Functions</div>
                </div>

                <div className="classroom-box">
                    <div className="box-header medium-text light-bold" style={{ textAlign: "center" }}>Class</div>

                    <div className="student-details">
                        <div>
                            <p className="small-text light-bold" style={{ color: "#a0abc4" }}>ID</p>
                            <div className="line header-line"></div>
                            {studentList.map(student => {
                                return (
                                    <div className="single-detail-box small-text" style={{ color: "white" }}>{student.studentId}</div>
                                )
                            })}
                        </div>
                        <div>
                            <p className="small-text light-bold" style={{ color: "#a0abc4" }}>Name</p>
                            <div className="line header-line"></div>
                            {studentList.map(student => {
                                return (
                                    <div className="single-detail-box small-text" style={{ color: "white" }}>{student.studentName}</div>
                                )
                            })}
                        </div>
                        <div>
                            <p className="small-text light-bold" style={{ color: "#a0abc4" }}>Status</p>
                            <div className="line header-line"></div>
                            {studentList.map(student => {
                                return (
                                    <div className="single-detail-box small-text" style={{ color: student.color }}>{student.status}</div>
                                )
                            })}
                        </div>
                        <div>
                            <p className="small-text light-bold" style={{ visibility: "hidden" }}>Code</p>
                            {studentList.map(student => {
                                return (
                                    <div className="single-detail-box small-text view-code-btn">View Code</div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeacherDemo0