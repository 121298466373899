import "../../../styles/protected/navbar/NavbarBurger.css"

const NavbarBurger = () => {
    return (
        <div className='navbar-burger-container'>
            <p className="small-text light-bold">Solutions</p>
            <div>
                <p className="small-text" style={{ color: "white" }}>Students</p>
                <p className="small-text" style={{ color: "white" }}>Teachers</p>
            </div>
            <div className="navbar-line"></div>
            <p className="small-text light-bold">Resources</p>
            <div style={{ width: "110px" }}>
                <p className="small-text" style={{ color: "white" }}>Proven Impact</p>
            </div>
            <div className="navbar-line"></div>
            <p className="small-text light-bold">Company</p>
            <div>
                <p className="small-text" style={{ color: "white" }}>Leadership</p>
                <p className="small-text" style={{ color: "white" }}>Contact</p>
            </div>
        </div>
    )
}

export default NavbarBurger