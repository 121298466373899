import "../../../../styles/protected/content/TeacherDemo.css"
import "../../../../styles/protected/content/TeacherDemo2.css"
import "../../../../styles/protected/content/TeacherDemo3.css"
import { FaLaptopCode, FaRegLightbulb, FaTools } from "react-icons/fa"
import { BiBot, BiChevronRight } from "react-icons/bi"
import { RiCloseCircleFill } from "react-icons/ri"
import { IoSend } from "react-icons/io5"
import { MdVerified, MdOutlineDownloadDone } from "react-icons/md"
import "../../../../styles/protected/content/StudentDemo.css"
import { useEffect, useState } from "react"
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';
import { HiPencil } from "react-icons/hi"
import MessageComponent from "./MessageComponent"
import MessageDetails from "../../../../hard_coded/MessageDetails"

const TeacherDemo3 = () => {
    const [editBtnClicked, setEditBtnClicked] = useState(false)
    const [postBtnClicked, setPostBtnClicked] = useState(false)
    const [msgDetails, setMsgDetails] = useState(MessageDetails)
    const [successBtnShadow, setSuccessBtnShadow] = useState(true)

    const lineNums = []
    for (let i = 1; i <= 15; i++) {
        lineNums.push(i)
    }

    useEffect(() => {

        const wrongCodeMsg = setTimeout(() => {
            setSuccessBtnShadow(false)
        }, 3000)

        return () => {
            clearTimeout(wrongCodeMsg)
        }
    }, [])

    return (
        <div className="teacher-demo-container">
            <div className="icons-box">
                <div className="line-marker">
                    <div className="icon-btn"><FaLaptopCode className="icon medium-icon" style={{ color: "#FF6363" }} /></div>
                    <p className="icon-title very-small-text light-bold">Incorrect Program</p>

                </div>
                <div className="line-marker">
                    <div className="icon-btn"><BiBot className="icon medium-icon" style={{ color: "#33DAFF" }} /></div>
                    <p className="icon-title very-small-text light-bold">Automated Hint</p>
                </div>
                <div className="line-marker">
                    <div className="icon-btn" style={{ boxShadow: successBtnShadow ? "3px 3px 80px rgba(99, 255, 99, 1), -3px -3px 80px rgba(99, 255, 99, 1)" : "" }}><MdVerified className="icon medium-icon" style={{ color: "#33FF6C" }} /></div>
                    <p className="icon-title very-small-text light-bold" >Query Resolved</p>

                </div>
            </div>

            <div className="code-box-teacher" style={{ flexDirection: "column" }}>
                <div className="folder-box">
                    <p className="small-text" style={{ color: "#a0abc4" }}>Loops</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <p className="small-text" style={{ color: "#a0abc4" }}>Prime Number</p>
                    <BiChevronRight className="small-icon" style={{ margin: "0 5px", color: "#a1acc5" }} />
                    <p className="small-text" style={{ color: "white" }}>John's code</p>
                </div>

                <div className="code-box" style={{ position: "relative", border: "0px" }}>
                    <div className="student-code" style={{ border: "0px", borderTopRightRadius: "0px" }}>
                        <div className="code-line-numbers">
                            {lineNums.map(num => {
                                return (
                                    <div className="line-numbers teacher-line-numbers chat-msg-line-numbers small-text light-bold" style={{ color: "#6E7681" }}>{num}</div>
                                )
                            })}
                            <div className="hint-line-pointer chat-line-pointer"  ></div>
                            <div className="hint-line teacher-hint-line chat-hint-line" style={{ borderColor: "#33daff" }}></div>

                            <div className="hint-box teacher-edit-hint-box teacher-student-chat-box" >
                                <div className="day-box very-small-text light-bold">Comments</div>
                                {msgDetails.map(msg => (
                                    <MessageComponent {...msg} />
                                ))}
                                <div>
                                    <div>Type message</div>
                                    <div><IoSend /></div>
                                </div>
                            </div>

                        </div>
                        <div className="code" style={{ border: "0px" }}>
                            <p className="line-numbers chat-msg-line-numbers small-text light-bold" style={{ color: "#6E7681" }}># Write a python program that takes as input a number N and</p>
                            <p className="line-numbers chat-msg-line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns Yes if the number is prime </p>
                            <p className="line-numbers chat-msg-line-numbers small-text light-bold" style={{ color: "#6E7681" }}># returns No otherwise </p>
                            <p className="line-numbers chat-msg-line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                            <p className="line-numbers chat-msg-line-numbers small-text light-bold"><span style={{ color: "#FF6E4F" }}>def</span> <span style={{ color: "#C7A8F2" }}>is_prime</span>(n):</p>
                            <p className="line-numbers chat-msg-line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp; <span style={{ color: "#FF6E4F" }}>if</span> n <span style={{ color: "#3CD6EB" }}>== 1</span>:</p>
                            <p className="line-numbers chat-msg-line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> "No"</p>
                            <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>for</span> i in <span style={{ color: "#FF6E4F" }}>range</span> (<span style={{ color: "#3CD6EB" }}>2</span>, n):</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}></span>if n<span style={{ color: "#3CD6EB" }}> / </span>i <span style={{ color: "#3CD6EB" }}>==</span> 0:</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “No”</p>
                            <p className="line-numbers small-text light-bold" style={{ visibility: "hidden" }}>1</p>
                            <p className="line-numbers small-text light-bold">&nbsp;&nbsp;&nbsp;  <span style={{ color: "#FF6E4F" }}>return</span> “Yes”</p>
                        </div>
                    </div>

                    {/* {failedMessage &&
                        <div className="failed-box-teacher">
                            <div className="failed-msg-box" >
                                <RiCloseCircleFill className="small-icon" style={{ color: "#FF6363" }} />
                                <p className="small-text" style={{ color: "#FF6363" }}>Incorrect Solution</p>
                            </div>
                        </div>
                    } */}


                </div>
            </div>
        </div>
    )
}

export default TeacherDemo3