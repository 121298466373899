import { createContext } from "react"

export interface GlobalContent {
    scrollPosition: number,
    setScrollPosition: React.Dispatch<React.SetStateAction<number>>,
    demoBtn1: boolean,
    setDemoBtn1: React.Dispatch<React.SetStateAction<boolean>>,
    demoBtn2: boolean,
    setDemoBtn2: React.Dispatch<React.SetStateAction<boolean>>,
    demoBtn3: boolean,
    setDemoBtn3: React.Dispatch<React.SetStateAction<boolean>>,
}

export const GlobalContext = createContext<GlobalContent>({
    scrollPosition: 0,
    setScrollPosition: () => { },
    demoBtn1: true,
    setDemoBtn1: () => { },
    demoBtn2: false,
    setDemoBtn2: () => { },
    demoBtn3: false,
    setDemoBtn3: () => { }
})